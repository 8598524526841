@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.text-about {
  padding: 52px 150px 115px;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  color: #7c848a;
}

@media (max-width: 1100px) {
  .phoneVisible {
    display: none;
  }
  .mobileFooter {
    display: none;
  }
  .mobileFont {
    font-size: 1.2rem !important;
  }
  .text-about {
    padding: 5px 10px 15px;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    color: #7c848a;
  }
}

@media screen and (max-width: 800px) {
  div.phoneUnvisible {
    display: none !important;
  }
}
